.list-of-topics {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0;
    list-style: none;
}

.topic {
    font-size: 2rem;
}