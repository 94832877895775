@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

:root {

  --var-color-dark: rgb(60, 60, 60);
  --var-color-light: rgb(175, 175, 175);
  --var-color-xlight: rgb(234, 234, 234);

  --var-color-green: rgb(88, 204, 2);

}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-size: 62.5%;
  font-family: 'Varela Round',
    sans-serif;
  color: var(--var-color-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: grid;
  grid-template-rows: 100px 1fr 100px;
}

.quiz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.video-player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.word {
  font-size: 2rem;
  text-transform: uppercase;
}

.btn {
  font-size: 1.2rem;
  cursor: pointer;
  background-color: white;
  border: solid var(--var-color-light);
  border-radius: 1.5rem;
  color: var(--var-color-dark);
  padding: 12px 20px 12px 20px;
  transition: all 200ms;
  border-width: 2px 2px 4px;
}

.btn:hover {
  scale: 1.05;
  transform: translateY(-5px);
}

.logo {
  color: var(--var-color-green);
  ;
  font-weight: bold;
  font-size: 1.6rem;
}

video {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0px 20px 20px -10px #4e4e4e;
  border-radius: 10px;
  width: 90vw;
  max-width: 500px;
}

header {
  padding: 2rem;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  padding: 1rem;
  align-self: baseline;
  height: 50px;
  display: flex;
  justify-content: center;

}

.progress-bar {
  width: 80vw;
  height: 1rem;
  /* background-color: ; */

  background-color: rgb(230, 230, 230);
  border-radius: 10px;
}

.video-loading {
  height: 370px;
  max-width: 500px;
  background-color: var(--var-color-xlight);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  border-radius: 10px;
  border-radius: 10px;
  width: 90vw;
  max-width: 500px;
}


.word__link a:link,
.word__link a:visited {
  text-decoration: none;
  border-bottom: 0.1px solid var(--var-color-light);
  padding-bottom: 2.5px;
  font-size: 0.8rem;
  color: var(--var-color-dark);
}

.disabled {
  opacity: 0.2;
}